/**
 * @file
 * Styles for quicklook display of product categories.
*/

@import "variables";

// "X" TO CLOSE
.mfp-close-btn-in .mfp-close {
  background: url("../../images/sprites/icons/close.png") center/200% no-repeat;
}

// QUICKLOOK MODAL
.product-quick-view-popup .view-mode-commerce_quicklook {

  display: grid;
  grid-template: auto auto auto / 50% 50%;
  padding: 0px;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: block;
  }

  .field-name-title {
    grid-column: span 2;

    // TITLE
    h2 {
      font-size: 1.8em;
      text-align: left;
      line-height: 1.1em;
      margin: 0;

      height: auto;
      display: block;
      background-color: $blue-dark;
      padding: 20px;
      border: 0px;
      border-bottom: 20px solid $orange;

      a {
        font-family: $primary-typeface;
        color: $white;
      }
    }

  }

  // GROUP FIELDS
  .field-group-div.group-1,
  .field-group-div.group-2,
  .field-group-div.group-3 {

    padding: 20px;

    p {
      text-align: left;
      margin-bottom: 1rem;
    }

    ul {
      padding-left: 10px;
      text-align: left;
    }
  }

  .field-group-div.group-3 {
    grid-column: span 2;
  }

  .commerce-product-field {

    // PRODUCT IMAGE
    .field-type-image {
      float:none;
      border: none;
      margin: 0;
      max-width: 100%;
      max-height: 100%;

      img {
        margin: 0;
      }

      .field-item {
        display: none;
      }

      .field-item:first-of-type {
        display: block;
      }
    }

    // PRODUCT PRICE
    .field-type-commerce-price {
      background: $blue-dark;
      font-family: $secondary-typeface;
      color: white;
      text-align: center;
      font-size: 1.5em;
      line-height: 1em;
      padding: 10px;
      margin-bottom: 1rem;
    }
  }

  .field-type-commerce-product-reference {

    // ADD TO CART FORM
    .commerce-add-to-cart {
      //padding: 0 20px;

      .messages {
        margin: 0 0 20px;
      }

      .attribute-widgets { // fields for product attributes ex: size
        text-align: center;

        .form-item {
          display: inline-block;
          padding: 0;
          margin: 0 10px 10px;
          width: auto;
          max-width: unset;
          float: none !important;

          &.form-item-attributes-field-mounting,
          &.form-item-attributes-field-flag-fringe {
            display: block;
          }

          ul.asImgSelect {
            display: inline-block;
            width: auto;
            text-align: center;
            li {
              margin: 0 10px;
            }
          }

          .tool-text-toc {
            margin-left: .2em;
            width: 1em;
            height: 1em;
            top: 2px;
            background-size: 100%;
          }
        }
        label {
          display: inline;
          font-family: $primary-typeface;
          font-size: 1em;
          color: $black;
        }
        select {
          width: auto;
        }
      }

      // QUANTITY FIELD
      .form-item-quantity {
        display: inline-block;
        line-height: 1em;
        position: relative;
        top: 1px;

        padding: 0px;
        margin: 0;

        label {
          display: none;
        }

        input {
          font-size: 1.5em;
          line-height: 1em;
          text-align: center;
          padding: 7.5px;
        }
      }

      // ADD TO CART BUTTON
      .form-submit {

        background-color: $orange;
        display: inline-block;
        color: white;
        font-size: 1.5em;
        line-height: 1em;
        margin: 0;
        padding: 10px;

        &:hover {
          background: lighten($orange, 5%);;
        }
      }
    }
  }
}
